@tailwind base;
@tailwind components;
@tailwind utilities;

body, html {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

video {
  max-width: initial;
}